<template>
    <div class="TextInfo center-wrap">
        <div class="html-box" v-html="text"></div>
    </div>
</template>
<script>
export default {
    name: 'TextInfo',
    data() {
        return {
            key: null,
            text: null
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.key = this.$route.query.key;
            document.title = this.$route.query.title;
            this.common.httpPost("/api/ajax/getSystemText", {
                key: this.key
            }).then((ret) => {
                if (ret.code == 1) this.text = ret.data;
            })
        }
    },
    watch: {
        $route(to, from) {
            this.init();
        }
    }
}
</script>
<style scoped>
.text-title {
    text-align: center;
    color: #000;
    font-size: 24px;
    width: 500px;
    margin: 40px auto 0;
    font-family: "PingFangSC-Medium", sans-serif;
}

.html-box {
    min-height: 400px;
    padding: 50px 83px;
}
</style>